@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Roboto:wght@300;400;500;700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Arima:wght@100;300;400;500;700&family=Eczar:wght@400;500;700&family=Hind+Madurai:wght@300;400;500;700&family=Hind+Siliguri:wght@300;400;500;700&family=Laila:wght@300;400;500;700&family=Noto+Sans+Malayalam:wght@100;300;400;500;700&family=Noto+Sans+Tamil:wght@100;300;400;500;700&family=Noto+Sans:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,300;1,400&family=Playfair+Display:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Poppins:ital,wght@0,300;0,400;0,500;0,700;1,400&family=Rozha+One&family=Tiro+Bangla:ital@0;1&family=Tiro+Telugu:ital@0;1&display=swap");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.blur {
    opacity: 0.3;
}
.div4mo{
    display: none;
}
.card-container {
    font-family: Roboto;
    color: white;
    max-width: 358px;
    width: 100%;
    height: 100vh;
    background-color: #120238;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    transition: all .5s ease-in-out;
    box-shadow: 0 0px 8px 0 #00000073;
    /* z-index: 999999; */
}

.visible {
    transform: translateX(0);
}

.closing {
    transform: translateX(-125%);
}

 .visible1 {
    transform: translateX(0);
}

.closing1 {
    transform: translateX(125%);
} 


.username {
    
    height: 44px;
    margin: 0 16px;
    font-weight: bold;
}
.span_head {
    font-weight: 700;
    font-size: 16px;
    position: relative;
    width: 100%;
    display: block;
    margin: 20px 20px 10px 20px;
}
.account {
    width: 100%;
}
.settings {
    width: 100%;
}
.options {
    display: flex;
    height: 42px;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin: 0 auto;
    padding: 6px 0;
    cursor: pointer;
    margin-bottom: 8px;
}
.details-container {
    width: 100%;
    max-height: 428px;
    gap: 32px;
}
.button-container {
    width: 100%;
    height: 10%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    position: relative;
}
.icons {
    border: 1px solid white;
}
.btn {
    width: 90%;
    height: 48px;
    border: none;
    border-radius: 5px;
    border: .1px solid white;
    font-size: 20px;
    font-weight: bolder;
    cursor: pointer;
    box-shadow: 0px 0px 2px;
}
.inner {
    width: 90%;
    display: flex;
    align-items: center;
    height: 30px;
    padding: 8px 0 12px 0;
}
.arrow {
    width: 10%;
    text-align: end;
}
.topic {
    padding-left: 20px;
    font-size: 14px;
}
.close1 {
    position: absolute;
    top: 5px;
    right: -38px;
    cursor: pointer;
}
.close2{
    position : absolute;
    left: -38px;
    top: 5px;
    cursor: pointer;
}
.btn_name {
    font-weight: 900;
}
@media (max-width: 769px) {
.card-container {
    max-width: 100vw;
    width: 100%;
    height: 95.5%;
    overflow-x:hidden;
}
.close1, .close2{
    display: none;  
}
.div4mo{
    display: block;
}
.close{
    display: block;
    cursor: pointer;
}
}
.loader-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh; 
    text-align: center;
  }
  .loader {
    border: 16px solid #65a2ce; 
    border-top: 16px solid #b4b4b4; 
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 1.5s linear infinite;
    margin-bottom: 20px; 
  }
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  